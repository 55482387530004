<template>
  <div class="statisticsAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="90px" size="small">
          <el-form-item label="场所:" prop="homeId">
            <el-select v-model="inform.homeId" placeholder="请选择场所">
              <el-option v-for="(item,index) in dicObj.homeList" :key="index" :label="item.homeName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="资产名称:" prop="assetItemName">
            <el-input type="text" v-model="inform.assetItemName" placeholder="请输入资产名称"></el-input>
          </el-form-item>
          <el-form-item label="资产编号:" prop="assetItemCode">
            <el-input type="text" v-model="inform.assetItemCode" placeholder="请输入资产编号"></el-input>
          </el-form-item>
          <el-form-item label="资产大类:" prop="assetRootId">
            <el-select v-model="inform.assetRootId" placeholder="请选择资产大类" @change="changeAsset">
              <el-option v-for="(item,index) in assetList" :key="index" :label="item.assetName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="资产小类:" prop="assetId">
            <el-select v-model="inform.assetId" placeholder="请选择资产小类" no-data-text="请先选择资产大类">
              <el-option v-for="(item,index) in assetSubList" :key="index" :label="item.assetName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所在位置:" prop="position">
            <el-input type="text" v-model="inform.position" placeholder="请输入所在位置"></el-input>
          </el-form-item>
          <el-form-item label="状态:" prop="status">
            <el-switch v-model="inform.status" active-color="#13ce66" inactive-color="#ff4949" inactive-text="停用" active-text="正常" active-value="1" inactive-value="0"></el-switch>
          </el-form-item>
          <el-form-item label="图片:">
            <div class="imgDiv">
              <div class="item" v-for="(item,index) in inform.filePicListShow" :key="index" v-if="inform.filePicListShow.length>0">
                <el-image style="width: 58px; height: 58px" :src="item.url" fit="cover"></el-image>
                <div class="btn">
                  <i class="el-icon-zoom-in" @click="iconPreview(item.url)"></i>
                  <i class="el-icon-delete" @click="delIcon(index,item,'1')"></i>
                </div>
              </div>
              <el-upload
                class="uploader"
                action="#"
                ref="upload"
                multiple
                :show-file-list="false"
                list-type="picture-card"
                :http-request="httpRequest"
                :before-upload="beforeUpload">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="备注:" prop="remark">
            <el-input type="textarea" :rows="4" v-model="inform.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>

    <el-dialog title="预览" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl,globalStr } from '@/assets/js/api'
import qs from 'qs'
import {delChildren,formatTime} from '@/utils/utils'
var vm;
export default {
  props:['dicObj'],
  data() {
    return {
      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        homeId:'',//场所ID
        assetItemName:'',//资产名称
        assetItemCode:'',//资产编号
        assetRootId:'',//资产大类
        assetId:'',//资产小类
        position:'',//所在位置
        remark:'',//备注
        status:'1',//状态
        filePicList:[],//场所环境
        filePicListShow:[],//场所环境显示
        num1:0,
      },
      assetList:[],//资产大类
      assetSubList:[],//资产小类

      rules :{
        homeId: [
          { required: true, message: '请选择场所', trigger: 'change' },
        ],
        assetItemName: [
          { required: true, message: '请输入资产名称', trigger: 'blur' },
        ],
        assetItemCode: [
          { required: true, message: '请输入资产编号', trigger: 'blur' },
        ],
        assetRootId: [
          { required: true, message: '请选择资产大类', trigger: 'change' },
        ],
        assetId: [
          { required: true, message: '请选择资产小类', trigger: 'change' },
        ],
        position: [
          { required: true, message: '请输入所在位置', trigger: 'blur' },
        ],
      },

      //查看上传的图片
      dialogVisible:false,
      dialogImageUrl:'',
    }
  },
  created(){
    vm = this
  },
  mounted(){
    
  },

  //方法
  methods: {
    //初始化
    async init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '640px'
      await vm.getAssetList()
      vm.isDio = true
      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '新增资产统计'
        }else if(type == 'edit'){
          vm.dioTit = '编辑资产统计'
          vm.assetStatsDetail(item.id)
        }
      })
    },
    //获取场景详情
    async assetStatsDetail(id){
      const res = await apiUrl.assetStatsDetail(id)
      if(res?.code==200){
        let arr1 = []
        if(res.data.assetItemPic!=''){
          let arr = res.data.assetItemPic.split(',')
          for(var i in arr){
            let obj = {
              url:`${globalStr}/fileView${arr[i]}`
            }
            arr1.push(obj)
          }
        }
        vm.inform = {
          id:res.data.id,
          homeId:res.data.homeId,//场所ID
          assetItemName:res.data.assetItemName,//资产名称
          assetItemCode:res.data.assetItemCode,//资产编号
          assetRootId:res.data.assetRootId,//资产大类
          assetId:res.data.assetId,//资产小类
          position:res.data.position,//所在位置
          remark:res.data.remark,//备注
          status:`${res.data.status}`,//状态
          filePicList:[],//场所环境
          filePicListShow:arr1,//场所环境显示
          num1:arr1.length,
        }
      }else{
        vm.$message.error(res.message)
      }
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='add'){
            vm.addAssetStats()
          }else if(vm.dioType=='edit'){
            vm.updateAssetStats()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      vm.inform = {
        id:'',
        homeId:'',//场所ID
        assetItemName:'',//资产名称
        assetItemCode:'',//资产编号
        assetRootId:'',//资产大类
        assetId:'',//资产小类
        position:'',//所在位置
        remark:'',//备注
        status:'1',//状态
        filePicList:[],//场所环境
        filePicListShow:[],//场所环境显示
        num1:0,
      }
      vm.assetList = []
      vm.assetSubList = []
      vm.isDio = false
    },
    //新增
    async addAssetStats(){
      let dataObj = new FormData();
      dataObj.append('areaId', vm.$store.state.csType)//区域ID
      dataObj.append('homeId', vm.inform.homeId)//场所ID
      dataObj.append('assetItemName', vm.inform.assetItemName)//资产名称
      dataObj.append('assetItemCode', vm.inform.assetItemCode)//资产编号
      dataObj.append('assetRootId', vm.inform.assetRootId)//资产大类
      dataObj.append('assetId', vm.inform.assetId)//资产小类
      dataObj.append('position', vm.inform.position)//所在位置
      dataObj.append('remark', vm.inform.remark)//备注
      dataObj.append('status', vm.inform.status)//状态
      if(vm.inform.filePicList.length>0){//场所环境
        for(var i in vm.inform.filePicList){
          dataObj.append('filePicList['+i+'].file',vm.inform.filePicList[i])
        }
      }
      const res = await apiUrl.addAssetStats(dataObj,100000)
      if(res?.code==200){
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改
    async updateAssetStats(){
      let dataObj = new FormData();
      dataObj.append('id', vm.inform.id)//ID
      dataObj.append('areaId', vm.$store.state.csType)//区域ID
      dataObj.append('homeId', vm.inform.homeId)//场所ID
      dataObj.append('assetItemName', vm.inform.assetItemName)//资产名称
      dataObj.append('assetItemCode', vm.inform.assetItemCode)//资产编号
      dataObj.append('assetRootId', vm.inform.assetRootId)//资产大类
      dataObj.append('assetId', vm.inform.assetId)//资产小类
      dataObj.append('position', vm.inform.position)//所在位置
      dataObj.append('remark', vm.inform.remark)//备注
      dataObj.append('status', vm.inform.status)//状态
      if(vm.inform.filePicList.length>0){//场所环境
        for(var i in vm.inform.filePicList){
          dataObj.append('filePicList['+i+'].file',vm.inform.filePicList[i])
        }
      }
      if(vm.inform.filePicListShow.length>0){//改变后场所环境
        // 首先使用filter过滤出没有uid的元素  
        const fileArr = vm.inform.filePicListShow.filter(item => !('uid' in item));
        // 然后使用map提取这些元素的name值  
        const arr1 = fileArr?.map(item => item.url);
        const newArr1 = vm.getStrUrl(arr1)
        if(arr1.length>0 ){
        // if(arr1.length>0 && arr1.length!=this.inform.num1){
          dataObj.append('assetItemPic',newArr1.join(','))
        }else{
          dataObj.append('assetItemPic','')
        }
      }else{
        dataObj.append('assetItemPic','')
      }
      const res = await apiUrl.updateAssetStats(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //截取字符串
    getStrUrl(arr){ 
      const newArr = arr.map(url => {  
        const index = url.indexOf('/fileView');  
        if (index !== -1) {  
          // 提取从 /fileView 后的所有字符  
          return url.slice(index + '/fileView'.length);  
        } else {  
          // 如果找不到 /fileView，则返回原始 URL 或空字符串（根据你的需求）  
          return url; // 或者返回 ''  
        }  
      });
      return newArr
    },
    //大类改变
    changeAsset(val){
      vm.inform.assetId = ''
      vm.getAssetSubList()
    },
    //获取资产大类
    async getAssetList(){
      let dataObj = {
        'areaId':vm.$store.state.csType,//区域
        'homeId':vm.$store.state.homeId,//场所
      }
      const res = await apiUrl.getAssetList(qs.stringify(dataObj))
      if(res?.code==200){
        vm.assetList = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取资产小类
    async getAssetSubList(){
      let dataObj = {
        'areaId':vm.$store.state.csType,//区域
        'homeId':vm.$store.state.homeId,//场所
        'assetId':vm.inform.assetRootId,//资产大类ID
      }
      const res = await apiUrl.getAssetSubList(qs.stringify(dataObj))
      if(res?.code==200){
        vm.assetSubList = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    
    //移除文件
    delIcon(index,item,type) {
      if(!item.uid){
        if(type=='1'){
          this.inform.filePicListShow.splice(index, 1);
        }
      }else{
        if(type=='1'){
          for(var x=0;x<this.inform.filePicList.length;x++){
            if(this.inform.filePicList[x].uid === this.inform.filePicListShow[index].uid){
              this.inform.filePicList.splice(x,1)
              x-=1
            }
          }
          this.inform.filePicListShow.splice(index, 1);
        }
      }
    },
    //预览图片
    iconPreview(url) {
      this.dialogImageUrl = url;
      this.dialogVisible = true;
    },
    //上传格式校验
    beforeUpload (file){
      const isPNG = file.type ==='image/png' || file.type === 'image/jpg'||file.type === 'image/jpeg'
      const isLt500M = file.size / 1024 / 1024 < 500
      if (!isPNG) {
        vm.$message.warning('上传图片只能是JPG、JPEG或PNG 格式!')
      }
      if (!isLt500M) {
        vm.$message.warning('上传图片大小不能超过 500MB!')
      }
      return isPNG && isLt500M
      
    },
    // 文件提交
    async httpRequest (data){
      let that = this
      let rd =new FileReader()// 创建文件读取对象
      let file = data.file
      rd.readAsDataURL(file)// 文件读取装换为base64类型
      rd.onloadend =function (e) {
        that.inform.filePicList.push(data.file)
        let obj = {
          url:this.result,
          uid:data.file.uid
        }
        that.inform.filePicListShow.push(obj)
      }
    },
  }
}
</script>
<style lang='scss'>
.statisticsAddEdit{
  .contDio{
    max-height: 500px;
    overflow: auto;
    .imgDiv{
      display: inline-flex;
      flex-wrap: wrap;
      .item{
        margin-right: 5px;
        width: 58px;
        height: 58px;
        border: 1px solid #ddd;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        margin-bottom: 5px;
        .btn{
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          display: none;
          align-items: center;
          justify-content: space-around;
          i{
            font-size: 20px;
            cursor: pointer;
            color: #fff;
          }
        }
        &:hover{
          .btn{
            display: flex;
          }
        }
      }
    }
  }
  .uploader{
    .el-upload-list--picture-card{
      .el-upload-list__item{
        width: 60px;
        height: 60px;
      }
    }
    .el-upload--picture-card{
      width: 60px;
      height: 60px;
      line-height: 58px;
      background:none;
      i.el-icon-plus{
        line-height:inherit;
      }
    }
  }
}
</style>